import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
// import Layout from '../layout/Stage'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/index'
  // },
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    name: 'indexParent',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/index/Index'),
        meta: { title: '首页', icon: 'el-icon-house' }
      }
    ]
  },
  {
    path: '/monitor',
    name: 'Monitor',
    component: Layout,
    redirect: '/monitor/subreddit',
    meta: {
      title: '方案管理',
      icon: 'el-icon-aim'
    },
    children: [
      {
        path: 'subreddit',
        name: 'subreddit',
        component: () => import('@/views/monitor/Index.vue'),
        meta: {
          title: '社区方案',
          icon: 'el-icon-office-building'
        }
      },
      {
        path: 'user',
        name: 'user',
        component: () => import('@/views/monitor/Index2.vue'),
        meta: {
          title: '用户方案',
          icon: 'el-icon-user'
        }
      },
      {
        path: 'post',
        name: 'ByPostId',
        component: () => import('@/views/monitor/Index3.vue'),
        meta: {
          title: '帖子方案',
          icon: 'el-icon-tickets'
        }
      },
      {
        path: 'keyword',
        name: 'ByKeyword',
        component: () => import('@/views/monitor/Index4.vue'),
        meta: {
          title: '关键词方案',
          icon: 'el-icon-magic-stick'
        }
      }
      // {
      //   path: 'subreddit111',
      //   name: 'BySubredditName',
      //   component: () => import('@/views/monitor/SubredditMonitor.vue'),
      //   meta: {
      //     title: '社区监控111',
      //     icon: 'el-icon-office-building'
      //   }
      // },
      // {
      //   path: 'user111',
      //   name: 'ByUserName',
      //   component: () => import('@/views/monitor/UserMonitor.vue'),
      //   meta: {
      //     title: '帐号监控111',
      //     icon: 'el-icon-user'
      //   }
      // }
      // {
      //   path: 'post111',
      //   name: 'ByPostId',
      //   component: () => import('@/views/monitor/PostMonitor.vue'),
      //   meta: {
      //     title: '帖子监控111',
      //     icon: 'el-icon-tickets'
      //   }
      // }
      // {
      //   path: 'keyword111',
      //   name: 'ByKeyword',
      //   component: () => import('@/views/monitor/KeywordMonitor.vue'),
      //   meta: {
      //     title: '关键词监控111',
      //     icon: 'el-icon-magic-stick'
      //   }
      // }
    ]
  },
  {
    path: '/search',
    name: 'Search',
    component: Layout,
    redirect: '/search/users',
    meta: {
      title: '数据检索',
      icon: 'el-icon-search'
    },
    children: [
      {
        path: 'subreddits',
        name: 'SearchSubreddits',
        component: () => import('@/views/search/Index.vue'),
        meta: {
          title: '社区数据',
          icon: 'el-icon-office-building'
        }
      },
      {
        path: 'users',
        name: 'SearchUsers',
        component: () => import('@/views/search/Index2.vue'),
        meta: {
          title: '用户数据',
          icon: 'el-icon-user'
        }
      },
      {
        path: 'posts',
        name: 'SearchPosts',
        component: () => import('@/views/search/Index3.vue'),
        meta: {
          title: '帖子数据',
          icon: 'el-icon-tickets'
        }
      },
      {
        path: 'comments',
        name: 'SearchComments',
        component: () => import('@/views/search/Index4.vue'),
        meta: {
          title: '评论数据',
          icon: 'el-icon-chat-dot-square'
        }
      }
      // {
      //   path: 'subreddits',
      //   name: 'SearchSubreddits',
      //   component: () => import('@/views/search/Subreddit.vue'),
      //   meta: {
      //     title: '社区检索',
      //     icon: 'el-icon-office-building'
      //   }
      // },
      // {
      //   path: 'users',
      //   name: 'SearchUsers',
      //   component: () => import('@/views/search/User.vue'),
      //   meta: {
      //     title: '用户检索',
      //     icon: 'el-icon-user'
      //   }
      // },
      // {
      //   path: 'posts',
      //   name: 'SearchPosts',
      //   component: () => import('@/views/search/Post.vue'),
      //   meta: {
      //     title: '帖子检索',
      //     icon: 'el-icon-tickets'
      //   }
      // },
      // {
      //   path: 'comments',
      //   name: 'SearchComments',
      //   component: () => import('@/views/search/Comment.vue'),
      //   meta: {
      //     title: '评论检索',
      //     icon: 'el-icon-chat-dot-square'
      //   }
      // }
    ]
  },

  // {
  //   path: '/twitter',
  //   component: Layout,
  //   // alias: '/twitter',
  //   name: 'Twitter',
  //   redirect: '/twitter/account',
  //   meta: {
  //     title: 'Twitter'
  //     // icon: 'dashboard'
  //   },
  //   children: [
  //     {
  //       // alias: '/',
  //       path: 'account',
  //       name: 'Account',
  //       component: () => import('@/views/twitter/account.vue'),
  //       meta: {
  //         title: '推特帐号',
  //         icon: 'el-icon-user'
  //       }
  //     },
  //     {
  //       path: 'postTask',
  //       name: 'PostTask',
  //       component: () => import('@/views/twitter/postTask.vue'),
  //       meta: {
  //         title: '推特发帖',
  //         icon: 'el-icon-tickets'
  //       }
  //     },
  //     {
  //       path: 'forwardTask',
  //       name: 'ForwardTask',
  //       component: () => import('@/views/twitter/forwardTask.vue'),
  //       meta: {
  //         title: '推特转帖',
  //         icon: 'el-icon-s-promotion'
  //       }
  //     },
  //     {
  //       path: 'likeTask',
  //       name: 'LikeTask',
  //       component: () => import('@/views/twitter/likeTask.vue'),
  //       meta: {
  //         title: '推特点赞',
  //         icon: 'el-icon-aim'
  //       }
  //     },
  //     {
  //       path: 'commentTask',
  //       name: 'CommentTask',
  //       component: () => import('@/views/twitter/commentTask.vue'),
  //       meta: {
  //         title: '推特评论',
  //         icon: 'el-icon-chat-line-square'
  //       }
  //     },
  //     {
  //       path: 'batchUpload',
  //       name: 'BatchUpload',
  //       component: () => import('@/views/twitter/batchUpload.vue'),
  //       meta: {
  //         title: '批量上传',
  //         icon: 'el-icon-upload'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/facebook',
  //   component: Layout,
  //   // alias: '/twitter',
  //   name: 'Facebook',
  //   redirect: '/facebook/account',
  //   meta: {
  //     title: 'Facebook'
  //     // icon: 'twitter'
  //   },
  //   children: [
  //     {
  //       // alias: '/',
  //       path: 'account',
  //       name: 'Account',
  //       component: () => import('@/views/facebook/account.vue'),
  //       meta: {
  //         title: '脸书帐号',
  //         icon: 'el-icon-user'
  //       }
  //     },
  //     {
  //       path: 'postTask',
  //       name: 'PostTask',
  //       component: () => import('@/views/facebook/postTask.vue'),
  //       meta: {
  //         title: '脸书发帖',
  //         icon: 'el-icon-tickets'
  //       }
  //     }
  //   ]
  // },
  // {
  //   name: 'Manage',
  //   path: '/manage',
  //   component: Layout,
  //   redirect: '/manage/userSettings',
  //   meta: {
  //     title: '管理员',
  //     icon: 'el-icon-setting'
  //   },
  //   children: [
  //     {
  //       name: 'twitterDemoAccount',
  //       path: 'twitterDemoAccount',
  //       component: () => import('@/views/manage/twitterDemoAccount.vue'),
  //       meta: {
  //         title: '推特演示帐号',
  //         icon: 'el-icon-female'
  //       }
  //     },
  //     {
  //       name: 'UserSettings',
  //       path: 'userSettings',
  //       component: () => import('@/views/manage/userSettings.vue'),
  //       meta: {
  //         title: '用户管理',
  //         icon: 'el-icon-s-custom'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import('@/views/login/Login.vue')
  // },
  {
    path: '/authLogin',
    name: 'AuthLogin',
    component: () => import('@/views/AuthLogin.vue')
  },
  {
    path: '/version',
    name: 'Version',
    component: () => import('@/views/Version.vue')
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/errorPage/401.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
